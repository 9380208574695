@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?b22vem');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?b22vem#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?b22vem') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?b22vem') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?b22vem') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?b22vem##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tiktok {
  &:before {
    content: $icon-tiktok; 
  }
}
.icon-arrow-short-left {
  &:before {
    content: $icon-arrow-short-left; 
  }
}
.icon-arrow-short-right {
  &:before {
    content: $icon-arrow-short-right; 
  }
}
.icon-mute {
  &:before {
    content: $icon-mute; 
  }
}
.icon-unmute {
  &:before {
    content: $icon-unmute; 
  }
}
.icon-arrow-longer-right {
  &:before {
    content: $icon-arrow-longer-right; 
  }
}
.icon-next {
  &:before {
    content: $icon-next; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-prev {
  &:before {
    content: $icon-prev; 
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-arrow-long-left {
  &:before {
    content: $icon-arrow-long-left; 
  }
}
.icon-arrow-long-right {
  &:before {
    content: $icon-arrow-long-right; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-vk {
  &:before {
    content: $icon-vk; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-eye-closed {
  &:before {
    content: $icon-eye-closed; 
  }
}
.icon-eye-opened {
  &:before {
    content: $icon-eye-opened; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}


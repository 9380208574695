@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.scrollbar {
  width: 100%;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    z-index: 1;
    background: rgba(0,0,0,0);
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    transition: opacity 0.2s ease 0s;
    pointer-events: none;
  }
  &.has-bottom {
    &:before {
      opacity: 0;
    }
  }
}

.scrollbar-content {
  padding-right: 30px;
  width: 100%;
  max-width: 830px;
  @include media('screen', '<768px') {
    padding-right: 12px;
  }
}

.scrollbar-track {
  position: absolute;
  width: 5px !important;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 5px;
  padding: 0 0;
  background: #424242;
  z-index: 2;
}

.scrollbar-thumb {
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: inherit;
  height: 10px;
  transform: translateY(0px);
  background-color: #C4C4C4;
}

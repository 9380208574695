@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.section {
  padding: 20px 0;
  @include media('screen', '<768px') {
    padding: 10px 0;
  }
}

.episode {
  display: flex;
  flex-direction: row;

  .wrapper {
    display: block;
    flex: 1 1 auto;
  }

  .content {
    padding-top: 20px;
    padding-bottom: 40px;
    @include media('screen', '<768px') {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
  .block {
    width: 100%;
    max-width: 100%;
    background-color: #151515;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
    border-radius: 22px;
    position: relative;
    letter-spacing: 0.03em;
    padding: 55px 50px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @include media('screen', '<1260px') {
      padding: 45px 40px;
    }

    @include media('screen', '<1025px') {
      padding: 35px 30px;
    }

    @include media('screen', '<1024px') {
      padding: 25px 20px;
    }

    @include media('screen', '<768px') {
      flex-direction: column;
      padding: 22px;
      border-radius: 12px;
    }
  }
  .album {
    margin: 0 42px 0 0;
    max-width: 218px;
    width: 100%;
    font-size: 0;

    @include media('screen', '<1024px') {
      max-width: 180px;
      margin: 0 20px 0 0;
    }

    @include media('screen', '<768px') {
      max-width: 220px;
      margin: 0 0 24px 0;
    }

    @include media('screen', '<768px', 'portrait') {
      max-width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 25px;
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);

      @include media('screen', '<768px') {
        border-radius: 33px;
      }
    }
  }
  .visual {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .bottom {
    width: 100%;
    @include media('screen', '<768px') {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .data {
    color: #fff;
    letter-spacing: 0.03em;
    max-width: calc(85% - 30px);

    @include media('screen', '<1024px') {
      max-width: 100%;
    }
  }
  .title {
    color: inherit;
    display: block;
    text-align: left;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;

    @include media('screen', '<1024px') {
      font-size: 28px;
      margin-bottom: 10px;
    }

    @include media('screen', '<768px') {
      font-size: 24px;
    }
  }
  .subtitle {
    color: inherit;
    display: block;
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 15px;

    @include media('screen', '<1024px') {
      font-size: 18px;
    }

    @include media('screen', '<768px') {
      font-size: 16px;
    }
  }

  .description {
    color: inherit;
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.1;
  }

  .info {
    padding-bottom: 40px;
    @include media('screen', '<768px') {
      padding-bottom: 20px;
    }

    &__content {
      &_details {

      }
      &_transcript {
        padding-right: 30px;
        width: 100%;
        max-width: 830px;
        @include media('screen', '<768px') {
          padding-right: 12px;
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      font-size: 18px;
      font-weight: 500;

      @include media('screen', '<1260px') {
        font-size: 16px;
      }
    }

    &:hover {
      > span {
        text-decoration: underline;
      }
    }
  }

  .play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    background-color: #828282;
    border-radius: 50%;
    font-size: 18px;
    margin-left: 10px;

    @include media('screen', '<1260px') {
      width: 38px;
      height: 38px;
      font-size: 14px;
    }
  }

  [class^=icon-play] {
    margin-left: 2px;
  }

}

.listen {
  margin-top: 37px;
}

.only_desk {
  @include media('screen', '<768px') {
    display: none;
  }
}

.only_mob {
  display: none;
  @include media('screen', '<768px') {
    display: block;
  }
}

@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.rc-tabs-nav-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 45px;
  margin-top: 10px;

  @include media('screen', '<768px') {
    margin-bottom: 35px;
    margin-top: 20px;
  }


  .rc-tabs-tab {
    &:not(:first-child) {
      &:before {
        content: '/';
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        color: #fff;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    &.rc-tabs-tab-active {
      .rc-tabs-tab-btn {
        text-decoration: underline;
      }
    }

    &:not(.rc-tabs-tab-active) {
      .rc-tabs-tab-btn {
        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }


  }
  .rc-tabs-tab-btn {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    height: 15px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    @include media('screen', '<768px') {
      font-size: 12px;
    }
  }

  .rc-tabs-ink-bar {
    display: none;
  }
}

.rc-tabs-nav-operations-hidden {
  display: none;
}

@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.slick-slider {
  width: 100%;

  .slick-list {
    margin: 0 -15px;
    @include media('screen', '<1260px') {
      margin: 0 -10px;
    }
    @include media('screen', '<1024px') {
      margin: 0 -5px;
    }
  }

  .slick-arrow {
    position: absolute;
    display: block;
    width: 44px !important;
    height: 44px !important;
    bottom: 0;
    top: inherit!important;
    transform: translate(0);
    border-radius: 50%;
    font-size: 14px;
    padding: 0 0;
    font-size: 0;
    color: #E0E0E0;
    outline: none;
    border: none;
    transition: all .2s ease;
    z-index: 3;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);

    @include media('screen', '<768px') {
      right: 50% !important;
      background: transparent;
    }
    &:before {
      display: none;
    }
    &:after {
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      display: block;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      font-size: 12px;
      color: inherit;
    }

    &.slick-disabled {
      display: block !important;
      opacity: 0.4;
      pointer-events: none;
      background: transparent;
    }

    &.slick-prev {
      right: 68px;
      left: auto;
      text-indent: -2px;
      @include media('screen', '<768px') {
        text-indent: -1px;
        margin-right: -4px !important;
      }
      &:after {
        content: "\e919";
      }
    }

    &.slick-next {
      right: 12px;
      text-indent: 2px;
      @include media('screen', '<768px') {
        text-indent: 1px;
        margin-right: -50px !important;
      }
      &:after {
        content: "\e91a";
      }
    }
  }
}

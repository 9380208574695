@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.type_first {
  z-index: 1;

  &.song {
    position: relative;
    height: 100%;

    &:hover {
      .border {
        opacity: 1;
        transition: opacity .3s ease-in;
      }

      .content {
        border-color: transparent;
      }
    }
  }

  .border {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: 22px;
    opacity: 0;
    transition: opacity .2s ease-in;
    background: linear-gradient(to bottom, #F8FF13, #F8FF13);

    @include media('screen', '<1024px') {
      border-radius: 12px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    border-radius: 22px;
    position: relative;
    border: 1px solid #333333;
    background-color: #151515;
    height: 100%;
    padding: 45px;

    transition: border-color .3s ease-in;

    @include media('screen', '<1260px') {
      padding: 30px;
    }

    @include media('screen', '<1024px') {
      padding: 20px;
      border-radius: 12px;
    }

    @include media('screen', '<768px') {
      flex-direction: column;
      padding: 18px 24px;
    }

    @include media('screen', '<375px') {
      padding: 12px 15px;
    }
  }

  .img {
    margin-right: 45px;
    max-width: 243px;
    width: 100%;
    font-size: 0;

    flex: 1 0 243px;

    @include media('screen', '<1260px') {
      margin-right: 25px;
      max-width: 200px;
      flex: 1 0 200px;
    }

    @include media('screen', '<1024px') {
      max-width: 140px;
      flex: 1 0 140px;
    }

    @include media('screen', '<768px') {
      margin-right: 0;
      margin-bottom: 12px;
      max-width: 100%;
      flex: 1;
    }

    img {
      border-radius: 22px;
      width: 100%;
      height: auto;

      @include media('screen', '<768px') {
        border-radius: 9.5px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }

  .subtitle {
    display: block;
    color: #4F4F4F;
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 10px;
    width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include media('screen', '<1260px') {
      font-size: 16px;
    }

    @include media('screen', '<768px') {
      font-size: 12px;
      margin-bottom: 10px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    color: #fff;
    font-weight: 900;
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .2s ease-in;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      transition: all .2s ease-in;
      opacity: 0.6;
    }

    @include media('screen', '<1260px') {
      font-size: 20px;
      margin-bottom: 10px;
    }

    @include media('screen', '<768px') {
      font-size: 18px;
    }

    @include media('screen', '<375px') {
      font-size: 14px;
    }
  }

  .description {
    display: block;
    color: #828282;
    font-weight: 400;
    line-height: 1.4;
    position: relative;
    font-size: 12px;
    margin-bottom: 10px;
    height: 130px;
    overflow: hidden;
    cursor: pointer;

    @include media('screen', '<1024px') {
      height: 65px;
    }

    @include media('screen', '<768px') {
      font-size: 9px;
      line-height: 1.2;
      height: 40px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 35px;
      //background: linear-gradient(180deg, transparent, #151515 50%);

      background: rgba(21,21,21,0);
      background: -moz-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(21,21,21,0)), color-stop(100%, rgba(21,21,21,1)));
      background: -webkit-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -o-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -ms-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: linear-gradient(to bottom, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#151515', GradientType=0 );

      @include media('screen', '<768px') {
        height: 20px;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-bottom: 3px;
    > span {
      font-size: 18px;
      font-weight: 500;

      @include media('screen', '<1260px') {
        font-size: 16px;
      }

      @include media('screen', '<768px') {
        font-size: 12px;
      }
    }

    &:hover {
      > span {
        text-decoration: underline;
      }
    }

    &.amplitude-paused {
      span.icon-play {
        margin-left: 2px;
        &:before {
          //content: $icon-play;
        }
      }
    }

    &.amplitude-playing {
      span.icon-play {
        &:before {
          //content: $icon-pause;
        }
      }

    }
  }

  .play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #828282;
    border-radius: 50%;
    font-size: 10px;
    margin-left: 10px;

    @include media('screen', '<768px') {
      width: 20px;
      height: 20px;
      font-size: 8px
    }
  }

  &[data-key="1"] {

      .content {
        flex-direction: row;

        @include media('screen', '<768px') {
          padding: 10px;
        }
      }

      .subtitle {
        font-size: 22px;
        margin-bottom: 15px;

        @include media('screen', '<1260px') {
          font-size: 18px;
          margin-bottom: 10px;
        }

        @include media('screen', '<1024px') {
          font-size: 16px;
        }

        @include media('screen', '<768px') {
          font-size: 12px;
        }
      }

      .title {
        font-size: 36px;
        margin-bottom: 30px;

        @include media('screen', '<1260px') {
          font-size: 28px;
          margin-bottom: 20px;
        }

        @include media('screen', '<1024px') {
          font-size: 22px;
          margin-bottom: 15px;
        }

        @include media('screen', '<768px') {
          font-size: 18px;
          margin-bottom: 10px;
        }

        @include media('screen', '<375px') {
          font-size: 14px;
        }
      }

     .description {
        height: 98px;
        position: relative;

        @include media('screen', '<1024px') {
          height: 78px;
        }

        @include media('screen', '<768px') {
          font-size: 9px;
          height: 50px;
        }
      }

      .img {
        @include media('screen', '<768px') {
          margin-right: 13px;
          max-width: 106px;
        }
      }
  }

  &[data-key="2"] {

    .content {
      flex-direction: column;
      justify-content: space-between;
      padding: 42px;

      @include media('screen', '<1260px') {
        padding: 30px;
      }

      @include media('screen', '<1024px') {
        padding: 20px;
      }

      @include media('screen', '<768px') {
        padding: 18px 24px;
      }

      @include media('screen', '<375px') {
        padding: 12px 15px;
      }
    }

    .header {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      opacity: 0;
      transition: all .2s ease-in;
      padding: 42px;

      @include media('screen', '<1260px') {
        padding: 30px;
      }

      @include media('screen', '<1024px') {
        padding: 20px;
      }

      @include media('screen', '<768px') {
        position: static;
        opacity: 1;
        transform: none;
        top: 0;
        padding: 0;
      }
    }

    .img {
      margin-right: 0;
      max-width: 100%;

      @include media('screen', '<768px') {
        margin-bottom: 12px;
      }

    }

    .info {
      justify-content: flex-end;
      z-index: 1;
      overflow: visible;
    }

    .subtitle {
      color: #BDBDBD;

      @include media('screen', '<768px') {
        color: #4f4f4f;
      }
    }

    .description {
      margin-bottom: 10px;
      color: #BDBDBD;
      height: 64px;
      overflow: hidden;

      @include media('screen', '<768px') {
        color: #828282;
        height: 40px;
      }

      &:after {
        display: none;

        @include media('screen', '<768px') {
          display: block;
        }
      }
    }

    &:hover {

      .header {
        opacity: 1;
        transition: all .2s ease-in;
      }

      .content {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba(21, 21, 21, 0.9);
          border-radius: 22px;
          transition: all .2s ease-in;

          @include media('screen', '<1024px') {
            border-radius: 12px;
          }
          @include media('screen', '<768px') {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  &[data-key="4"] {

      .content {
        @include media('screen', '<768px') {
          flex-direction: row;
          padding: 10px;
        }
      }

      .description {
        @include media('screen', '<768px') {
          height: 50px;
        }
      }

      .img {
        @include media('screen', '<768px') {
          margin-right: 13px;
          max-width: 106px;
        }

      }
    }
}

.type_second {
    &.song {
      position: relative;
      height: 100%;

      &:hover {
        .border {
          opacity: 1;
          transition: opacity .3s ease-in;
        }
        .content {
          border-color: transparent;
        }
      }
    }

    .border {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      border-radius: 22px;
      opacity: 0;
      transition: opacity .2s ease-in;
      background: linear-gradient(to bottom, #F8FF13, #F8FF13);

      @include media('screen', '<1024px') {
        border-radius: 12px;
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      border-radius: 22px;
      position: relative;
      border: 1px solid #333333;
      background-color: #151515;
      height: 100%;
      padding: 45px;

      transition: border-color .3s ease-in;

      @include media('screen', '<1260px') {
        padding: 30px;
      }

      @include media('screen', '<1024px') {
        padding: 20px;
        border-radius: 12px;
      }

      @include media('screen', '<768px') {
        flex-direction: column;
        padding: 18px 24px;
      }

      @include media('screen', '<375px') {
        padding: 12px 15px;
      }
    }

    .img {
      margin-right: 45px;
      width: 100%;
      max-width: 243px;
      font-size: 0;

      @include media('screen', '<1260px') {
        margin-right: 25px;
        max-width: 200px;
      }

      @include media('screen', '<1024px') {
        max-width: 140px;
      }

      @include media('screen', '<768px') {
        margin-right: 0;
        margin-bottom: 12px;
        max-width: 100%;
      }

      img {
        border-radius: 22px;
        width: 100%;
        height: auto;

        @include media('screen', '<1024px') {
          border-radius: 12px;
        }

        @include media('screen', '<768px') {
          border-radius: 9.5px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    .subtitle {
      display: block;
      color: #4F4F4F;
      font-weight: 900;
      font-size: 18px;
      margin-bottom: 10px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include media('screen', '<1260px') {
        font-size: 16px;
      }

      @include media('screen', '<768px') {
        font-size: 12px;
        margin-bottom: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .title {
      color: #fff;
      font-weight: 900;
      display: block;
      font-size: 24px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all .2s ease-in;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        transition: all .2s ease-in;
        opacity: 0.6;
      }

      @include media('screen', '<1260px') {
        font-size: 20px;
        margin-bottom: 10px;
      }

      @include media('screen', '<768px') {
        font-size: 18px;
      }

      @include media('screen', '<375px') {
        font-size: 14px;
      }
    }

    .description {
      display: block;
      color: #828282;
      font-weight: 400;
      line-height: 1.4;
      position: relative;
      font-size: 12px;
      margin-bottom: 10px;
      height: 130px;
      overflow: hidden;
      cursor: pointer;

      @include media('screen', '<1024px') {
        height: 65px;
      }

      @include media('screen', '<768px') {
        font-size: 9px;
        line-height: 1.2;
        height: 40px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 35px;
        /*background: linear-gradient(180deg, transparent, #151515 50%);*/
        background: rgba(21,21,21,0);
        background: -moz-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(21,21,21,0)), color-stop(100%, rgba(21,21,21,1)));
        background: -webkit-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
        background: -o-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
        background: -ms-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
        background: linear-gradient(to bottom, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#151515', GradientType=0 );

        @include media('screen', '<768px') {
          height: 20px;
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 3px;
      > span {
        font-size: 18px;
        font-weight: 500;

        @include media('screen', '<1260px') {
          font-size: 16px;
        }

        @include media('screen', '<768px') {
          font-size: 12px;
        }
      }

      &:hover {
        > span {
          text-decoration: underline;
        }
      }

      &.amplitude-paused {
        span.icon-play {
          margin-left: 2px;
          &:before {
            //content: $icon-play;
          }
        }
      }

      &.amplitude-playing {
        span.icon-play {
          &:before {
            //content: $icon-pause;
          }
        }

      }
    }

    .play {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background-color: #828282;
      border-radius: 50%;
      font-size: 10px;
      margin-left: 10px;

      @include media('screen', '<768px') {
        width: 20px;
        height: 20px;
        font-size: 8px
      }
    }
}

.type_third {

  &.song {
    position: relative;
    height: 100%;

    &:hover {
      .border {
        opacity: 1;
        transition: opacity .3s ease-in;
      }
      .content {
        border-color: transparent;
      }
    }
  }

  .border {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: 22px;
    opacity: 0;
    transition: opacity .2s ease-in;

    @include media('screen', '<1024px') {
      border-radius: 12px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    border-radius: 22px;
    position: relative;
    border: 1px solid #333333;
    background-color: #151515;
    height: 100%;
    padding: 20px 15px;

    transition: border-color .3s ease-in;

    @include media('screen', '<1260px') {
      padding: 15px;
    }

    @include media('screen', '<1024px') {
      border-radius: 12px;
    }

    @include media('screen', '<768px') {
      padding: 15px 15px 10px !important;
      flex-direction: row !important;
    }

    @include media('screen', '<360px') {
      padding: 10px !important;
    }
  }

  .img {
    margin-right: 20px;
    border-radius: 12px;
    max-width: 122px;
    width: 100%;
    font-size: 0;

    @include media('screen', '<1260px') {
      margin-right: 10px;
      max-width: 100px;
    }

    @include media('screen', '<1100px') {
      max-width: 80px;
    }

    @include media('screen', '<1024px') {
      margin-right: 12px;
      max-width: 70px;
    }

    @include media('screen', '<768px') {
      margin-right: 12px !important;
      margin-bottom: 0 !important;
      max-width: 66px !important;
    }

    @include media('screen', '<360px') {
      margin-right: 10px !important;
      max-width: 60px !important;
    }

    img {
      border-radius: 10px;
      width: 100%;
      height: auto;

      @include media('screen', '<1024px') {
        border-radius: 6px;
      }

      @include media('screen', '<768px') {
        border-radius: 6px !important;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    flex: 1;
    overflow: hidden;

    @include media('screen', '<1024px') {
      display: flex;
      flex-direction: row;
    }

    @include media('screen', '<768px') {
      display: flex !important;
      flex-direction: row !important;
      width: 100%;
    }
  }

  .header {
    @include media('screen', '<1024px') {
      margin-right: 10px;
      overflow: hidden;
    }
  }

  .subtitle {
    display: block;
    color: #4F4F4F;
    font-weight: 900;
    font-size: 14px;
    margin-bottom: 5px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include media('screen', '<1260px') {
      font-size: 12px;
    }

    @include media('screen', '<768px') {
      font-size: 12px !important;
      margin-bottom: 5px !important;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    color: #fff;
    font-weight: 900;
    display: block;
    cursor: pointer;
    transition: all .2s ease-in;
    font-size: 16px;
    margin-bottom: 10px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      transition: all .2s ease-in;
      opacity: 0.6;
    }

    @include media('screen', '<1260px') {
      font-size: 14px;
    }

    @include media('screen', '<768px') {
      font-size: 18px !important;
      margin-bottom: 2px !important;
    }

    @include media('screen', '<360px') {
      font-size: 14px !important;
    }
  }

  .description {
    display: block;
    color: #828282;
    font-weight: 400;
    line-height: 1.2;
    position: relative;
    height: 45px;
    overflow: hidden;
    cursor: pointer;
    font-size: 10px;
    margin-bottom: 5px;

    @include media('screen', '<1024px') {
      margin-bottom: 0;
      line-height: 1.2;
      height: 30px;
      font-size: 9px;
    }

    @include media('screen', '<768px') {
      line-height: 1.2 !important;
      height: 30px !important;
      font-size: 9px !important;
      margin-bottom: 0 !important;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 20px;
      /*background: linear-gradient(180deg, transparent, #151515 50%);*/
      background: rgba(21,21,21,0);
      background: -moz-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(21,21,21,0)), color-stop(100%, rgba(21,21,21,1)));
      background: -webkit-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -o-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -ms-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: linear-gradient(to bottom, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#151515', GradientType=0 );

      @include media('screen', '<768px') {
        height: 20px !important;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 3px;
    @include media('screen', '<1024px') {
      align-self: flex-end;
    }

    > span {
      font-size: 14px;
      font-weight: 500;

      @include media('screen', '<1260px') {
        font-size: 12px;
      }

      @include media('screen', '<768px') {
        font-size: 12px !important;
      }
    }

    &:hover {
      > span {
        text-decoration: underline;
      }
    }

    &.amplitude-paused {
      span.icon-play {
        margin-left: 2px;
        &:before {
          //content: $icon-play;
        }
      }
    }

    &.amplitude-playing {
      span.icon-play {
        &:before {
          //content: $icon-pause;
        }
      }

    }
  }

  .play {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #828282;
    border-radius: 50%;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    font-size: 8px;

    @include media('screen', '<768px') {
      width: 20px !important;
      height: 20px !important;
      font-size: 8px !important;
    }
  }

  &[data-key="1"],
  &[data-key="2"],
  &[data-key="3"],
  &[data-key="4"]  {

    .content {
      flex-direction: column;
      justify-content: space-between;
      padding: 47px 42px;

      @include media('screen', '<1260px') {
        padding: 30px;
      }

      @include media('screen', '<1024px') {
        padding: 20px;
      }
    }

    .info {
      @include media('screen', '<1024px') {
        display: block;
      }
    }

    .img {
      margin-right: 0;
      margin-bottom: 25px;
      max-width: 100%;

      @include media('screen', '<1260px') {
        margin-bottom: 20px;
      }

      @include media('screen', '<1024px') {
        margin-bottom: 15px;
        margin-right: 0;
      }

      img {
        border-radius: 22px;

        @include media('screen', '<1024px') {
          border-radius: 12px;
        }

        @include media('screen', '<1024px') {
          border-radius: 12px;
        }

        @include media('screen', '<768px') {
          border-radius: 6px !important;
        }
      }
    }

    .subtitle {
      font-size: 18px;
      margin-bottom: 10px;

      @include media('screen', '<1260px') {
        font-size: 16px;
      }
    }

    .title {
      font-size: 24px;
      margin-bottom: 20px;

      @include media('screen', '<1260px') {
        font-size: 20px;
      }
    }

    .description {
      font-size: 12px;
      height: 65px;
      line-height: 1.4;
      margin-bottom: 0;

      &:after {
        height: 35px;
      }
    }

    .controls {
      > span {
        font-size: 18px;
        font-weight: 500;

        @include media('screen', '<1260px') {
          font-size: 16px;
        }

        @include media('screen', '<1024px') {
          font-size: 12px;
        }
      }
    }

    .play {
      width: 25px;
      height: 25px;
      @include media('screen', '<1024px') {
        width: 20px;
        height: 20px;
        font-size: 8px;
      }
    }
  }
}


@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.more {
  $self: &;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.12;
  text-align: right;
  color: #fff;
  padding: 0 0 0;
  transition: all 0.1s ease 0s;
  cursor: pointer;

  &:hover {
    #{$self}_text {
      color: lighten(#fff, 15%);
      text-decoration: underline;
    }
  }

  &_text {
    margin-right: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  &_icon {
    font-size: 12px;
    color: inherit;
  }
}

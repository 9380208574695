@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.item {
  position: relative;
  height: 100%;
  &:hover {
    .border {
      opacity: 1;
      transition: opacity .3s ease-in;
    }
    .content {
      border-color: transparent;
    }
  }

  .border {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    border-radius: 22px;
    opacity: 0;
    transition: opacity .2s ease-in;
    background: linear-gradient(to bottom, #F8FF13, #F8FF13);

    @include media('screen', '<1024px') {
      border-radius: 12px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 22px;
    position: relative;
    border: 1px solid #333333;
    background-color: #151515;
    height: 100%;
    padding: 42px;

    @include media('screen', '<1260px') {
      padding: 30px;
    }

    @include media('screen', '<1024px') {
      padding: 20px;
      border-radius: 12px;
    }

    @include media('screen', '<768px') {
      padding: 15px 15px 10px;
      flex-direction: row;
      overflow: hidden;
    }

    @include media('screen', '<375px') {
      padding: 10px;
    }
  }

  .img {
    margin-bottom: 25px;
    max-width: 100%;
    width: 100%;
    font-size: 0;

    @include media('screen', '<1260px') {
      margin-bottom: 20px;
    }

    @include media('screen', '<1024px') {
      margin-bottom: 15px;
      margin-right: 0;
    }

    @include media('screen', '<768px') {
      margin-bottom: 0;
      border-radius: 6px;
      margin-right: 12px;
      max-width: 66px;
      min-width: 66px;
    }

    @include media('screen', '<375px') {
      margin-right: 10px;
      max-width: 60px;
      min-width: 60px;
    }

    img {
      border-radius: 22px;
      width: 100%;
      height: auto;

      @include media('screen', '<1024px') {
        border-radius: 12px;
      }

      @include media('screen', '<768px') {
        border-radius: 9.5px;
      }

      @include media('screen', '<768px') {
        border-radius: 6px;
      }
    }
  }

  .header {
    @include media('screen', '<768px') {
      overflow: hidden;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    flex: 1;

    @include media('screen', '<768px') {
      flex-direction: row;
      overflow: hidden;
    }
  }

  .album {
    display: block;
    color: #4F4F4F;
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 10px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include media('screen', '<1260px') {
      font-size: 16px;
    }

    @include media('screen', '<768px') {
      font-size: 12px;
      margin-bottom: 5px;
    }

    //
    //&:hover {
    //  text-decoration: underline;
    //}
  }

  .name {
    color: #fff;
    font-weight: 900;
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all .2s ease-in;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    //&:hover {
    //  transition: all .2s ease-in;
    //  opacity: 0.6;
    //}

    @include media('screen', '<1260px') {
      font-size: 20px;
      margin-bottom: 10px;
    }

    @include media('screen', '<768px') {
      font-size: 18px;
      margin-bottom: 2px;
    }

    @include media('screen', '<375px') {
      font-size: 14px;
    }
  }

  .description {
    display: block;
    color: #828282;
    font-weight: 400;
    line-height: 1.4;
    position: relative;
    font-size: 12px;
    margin-bottom: 0;
    height: 65px;
    overflow: hidden;
    cursor: pointer;

    @include media('screen', '<768px') {
      font-size: 9px;
      line-height: 1.2;
      height: 30px;
      margin-bottom: 0;
      max-width: 380px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 35px;
      /*background: linear-gradient(180deg, transparent, #151515 50%);*/
      background: rgba(21,21,21,0);
      background: -moz-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(21,21,21,0)), color-stop(100%, rgba(21,21,21,1)));
      background: -webkit-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -o-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: -ms-linear-gradient(top, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      background: linear-gradient(to bottom, rgba(21,21,21,0) 0%, rgba(21,21,21,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#151515', GradientType=0 );

      @include media('screen', '<768px') {
        height: 20px;
      }
    }
  }
}




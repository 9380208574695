@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.listen {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 50px;

  @include media('screen', '<1260px') {
    margin-top: 40px;
  }

  @include media('screen', '<1024px') {
    margin-top: 35px;
  }
  &.small {
    margin: 0;
    width: auto;
    flex: 1;
    .content {
      justify-content: flex-end;

      @include media('screen', '<768px') {
        justify-content: flex-start;
      }
    }
    .icon {
      max-width: 36px;
      width: 36px;
      margin: 0 0 22px 22px;
      @include media('screen', '<1024px') {
        margin: 0 0 16px 16px;
      }
      @include media('screen', '<768px') {
        margin: 0 20px 20px 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.vertical {
    width: auto;
    padding-left: 32px;
    padding-top: 20px;
    margin: 0;

    @include media('screen', '<1024px') {
      padding-left: 15px;
    }

    .content {
      flex-direction: column;
    }

    .icon {
      margin: 0 0 20px 0;
      @include media('screen', '<1024px') {
        //margin-left: 10px;
      }
    }
  }
}

.header {
  margin-bottom: 35px;

  @include media('screen', '<1260px') {
    font-size: 20px;
    margin-bottom: 30px;
  }

  @include media('screen', '<768px') {
    font-size: 18px;
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  font-weight: 800;
  font-size: 24px;
  line-height: 1;
  color: #fff;
  letter-spacing: 0.03em;

  @include media('screen', '<1260px') {
    font-size: 20px;
  }

  @include media('screen', '<768px') {
    font-size: 18px;
  }
}

.icon {
  max-width: 67px;
  width: 67px;
  margin: 0 38px 0 0;
  font-size: 0;

  @include media('screen', '<1260px') {
    max-width: 60px;
    width: 60px;
  }

  @include media('screen', '<1024px') {
    max-width: 46px;
    width: 46px;
    margin: 0 30px 0 0;
  }

  @include media('screen', '<768px') {
    max-width: 40px;
    width: 40px;
    margin: 0 20px 20px 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  &:last-child {
    margin-right: 0;
  }
}




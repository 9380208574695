@import '../../node_modules/@csstools/normalize.css/normalize.css';

// SETTINGS
@import 'settings/settings';

// TOOLS
@import 'tools/media';
@import '../assets/fonts/icomoon/style.scss';
//@import 'tools/justify';
//@import 'tools/vertical';
//@import 'tools/center';

//components
@import "components/btn";

// GENERIC
@import 'generic/reset';
@import 'generic/fonts';

// ELEMENTS
@import 'elements/body';
@import 'elements/layout';

// SCOPE/THEME
@import 'scope/content';

// OBJECTS
@import 'objects/container';

@font-face {
  font-family: 'Gilroy';
  src: url('../../assets/fonts/gilroy/Gilroy-Regular.eot');
  src: url('../../assets/fonts/gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
  url('../../assets/fonts/gilroy/Gilroy-Regular.woff') format('woff'),
  url('../../assets/fonts/gilroy/Gilroy-Regular.ttf') format('truetype'),
  url('../../assets/fonts/gilroy/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../assets/fonts/gilroy/Gilroy-Medium.eot');
  src: url('../../assets/fonts/gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
  url('../../assets/fonts/gilroy/Gilroy-Medium.woff') format('woff'),
  url('../../assets/fonts/gilroy/Gilroy-Medium.ttf') format('truetype'),
  url('../../assets/fonts/gilroy/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../assets/fonts/gilroy/Gilroy-Bold.eot');
  src: url('../../assets/fonts/gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/gilroy/Gilroy-Bold.woff2') format('woff2'),
  url('../../assets/fonts/gilroy/Gilroy-Bold.woff') format('woff'),
  url('../../assets/fonts/gilroy/Gilroy-Bold.ttf') format('truetype'),
  url('../../assets/fonts/gilroy/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/gilroy/Gilroy-Extrabold.eot');
    src: url('../../assets/fonts/gilroy/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/gilroy/Gilroy-Extrabold.woff2') format('woff2'),
    url('../../assets/fonts/gilroy/Gilroy-Extrabold.woff') format('woff'),
    url('../../assets/fonts/gilroy/Gilroy-Extrabold.ttf') format('truetype'),
    url('../../assets/fonts/gilroy/Gilroy-Extrabold.svg#Gilroy-Extrabold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

//@font-face {
//  font-family: 'Gilroy';
//  src: url('../../assets/fonts/gilroy/Gilroy-Black.eot');
//  src: url('../../assets/fonts/gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
//  url('../../assets/fonts/gilroy/Gilroy-Black.woff2') format('woff2'),
//  url('../../assets/fonts/gilroy/Gilroy-Black.woff') format('woff'),
//  url('../../assets/fonts/gilroy/Gilroy-Black.ttf') format('truetype'),
//  url('../../assets/fonts/gilroy/Gilroy-Black.svg#Gilroy-Black') format('svg');
//  font-weight: 900;
//  font-style: normal;
//  font-display: swap;
//}




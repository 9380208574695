@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.section {
  position: relative;
  width: 100%;
  padding: 20px 0;
  //overflow: hidden;
  @include media('screen', '<768px') {
    padding: 10px 0;
  }
  &__full {
    width: auto;
    margin: 0 -15px;
    @include media('screen', '<1260px') {
      margin: 0 -10px;
    }
    @include media('screen', '<1024px') {
      margin: 0 -5px;
    }
    &_mob {
      margin: 0!important;
      width: 100%;
    }
  }
}

.slider {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  @include media('screen', '<768px') {
    padding-bottom: 40px;
  }


  .slick-list, .slick-track { touch-action:pan-y; }
}

.slider__justify {
  > div {
    @include media('screen', '>=768px') {
      width: 100%!important;
      margin: 0!important;
    }
    > div {
      @include media('screen', '>=768px') {
        margin: 0;
      }
    }
  }
  &_mob {
    > div {
      width: 100%!important;
      margin: 0!important;
      > div {
        margin: 0;
      }
    }
  }
}

.slide {

}


@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

// icons
@import "../../../assets/fonts/icomoon/variables";

.track {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 23px 0;
  width: 100%;
  @include media('screen', '<768px') {
    padding: 20px 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    /*background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #1a1a1a 34.38%, #1a1a1a 64.58%, rgba(255, 255, 255, 0) 100%);*/
    background: rgba(0,0,0,1);
    background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 34%, rgba(26,26,26,1) 64%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,1)), color-stop(34%, rgba(26,26,26,1)), color-stop(64%, rgba(26,26,26,1)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 34%, rgba(26,26,26,1) 64%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 34%, rgba(26,26,26,1) 64%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 34%, rgba(26,26,26,1) 64%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 34%, rgba(26,26,26,1) 64%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
    transition: opacity 0.2s ease 0s;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
    .play_pause {
      background: linear-gradient(140deg, var(--color-playlist-a), var(--color-playlist-b));
    }
  }

  .left_side {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right_side {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    margin-right: 38px;
    font-size: 0;
    @include media('screen', '<768px') {
      margin-right: 20px;
    }

    @include media('screen', '<360px') {
      margin-right: 15px;
    }

    img {
      max-width: 57px;
      border-radius: 6.5px;

      @include media('screen', '<360px') {
        max-width: 45px;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 30px;
    @include media('screen', '<768px') {
      flex-direction: column;
      margin-right: 15px;
    }
  }

  .name {
    display: block;
    margin-right: 30px;
    //max-width: 240px;
    //flex: 0 0 240px;
    max-width: 640px;
    flex: 0 0 640px;

    @include media('screen', '<1260px') {
      max-width: 480px;
      flex: 0 0 480px;
    }

    @include media('screen', '<1024px') {
      //max-width: 180px;
      //flex: 0 0 180px;
      max-width: 340px;
      flex: 0 0 340px;
    }

    @include media('screen', '<768px') {
      flex: 0 0 auto;
      margin-right: 0;
      max-width: 140px;
      width: 140px;
      font-size: 16px;
      //margin-bottom: 5px;
    }

    @include media('screen', '<360px') {
      font-size: 14px;
      max-width: 120px;
      width: 120px;
    }

    a {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      width: 100%;
      display: block;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: $primary_color;
        text-decoration: none;
      }
    }
  }

  .album {
    color: #E0E0E0;
    font-size: 18px;
    font-weight: normal;
    display: block;

    max-width: 220px;
    flex: 0 0 220px;

    @include media('screen', '<1024px') {
      max-width: 180px;
      flex: 0 0 180px;
    }

    @include media('screen', '<768px') {
      flex: 0 0 auto;
      font-size: 14px;
      max-width: 140px;
      width: 140px;
    }

    @include media('screen', '<360px') {
      font-size: 12px;
      max-width: 120px;
      width: 120px;
    }
  }

  .duration {
    font-size: 16px;
    margin-right: 80px;
    min-width: 70px;

    @include media('screen', '<1024px') {
      margin-right: 20px;
    }

    @include media('screen', '<768px') {
      font-size: 14px;
      margin-right: 15px;
      min-width: 50px;
    }

    @include media('screen', '<360px') {
      font-size: 12px;
    }
  }

  .controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    min-width: 128px;
    @include media('screen', '<768px') {
      min-width: auto;
    }

    &.amplitude-paused {
      .play_pause {
        //background: linear-gradient(140deg, var(--color-b), var(--color-a));

        span {
          margin-left: 3px;
          &:before {
            content: $icon-play;
          }
        }
      }
    }
  }

  .play_pause {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background-color: #828282;
    border-radius: 50%;
    font-size: 15px;
    > span {
      margin-left: 3px;
    }
  }

  .play {
    font-size: 16px;
    margin-left: 20px;
    @include media('screen', '<768px') {
      display: none;
    }
  }
}

.s-content {
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 0.03em;
  position: relative;
  height: auto;
  @include media('screen', '<768px') {
    font-size: 14px;
  }
  > * {
    &:last-child {
      margin-bottom: 0!important;
    }
  }

  p {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0 0 20px;
    &.details {
      white-space: pre-line;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 40px 0 20px;
  }

  .upper {
    text-transform: uppercase;
  }

  a {
    color: #fff;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  strong, b {
    font-weight: bold;
  }
  ul {
    list-style: none;
    color: inherit;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    margin: 0 0 40px;
    li {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 0;
      position: relative;
      text-decoration: underline;
      padding-left: 20px;

      @include media('screen', '<768px') {
       margin-bottom: 10px;
      }

      &:before {
        content: '•';
        position: absolute;
        left: 0;
      }
    }
  }
  ol {
    list-style: decimal;
    list-style-position: inside;
    color: inherit;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    margin: 0 0 40px;

    li {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      @include media('screen', '<768px') {
        margin-bottom: 10px;
      }
    }
  }
  table {
    color: #7F7F7F;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #7F7F7F;
    margin-bottom: 24px;
    @include media('screen', '<568px') {
      width: 100%!important;
    }
    th,
    td {
      width: 28%;
      line-height: 1;
      padding: 10px;
      border: 1px solid #7F7F7F;
      &:first-child {
        width: 16%;
      }
    }
  }

  img {
    margin-bottom: 24px;
    max-width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
    &.full {
      width: 100%;
      height: auto;
    }
  }


  &.transcript {
    font-size: 14px !important;
    line-height: 1.3 !important;
    p {
      margin: 0 0 20px;
    }
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin: 40px 0;
}

.embed-responsive::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

@include media('screen', '<568px') {
  .s-content {
    table {
      th,
      td {
        width: 28%;
        line-height: 1;
        padding: 5px;
      }
    }
  }
}

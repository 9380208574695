html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  min-height: 100%;
  width: auto;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: $primary_font;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #000;
  letter-spacing: 0.03em;

  //--blue: #3c4ffb;
  //--dark-blue: #13132a;
  //--grey: #80818A;
  //--red: #e22a36;
  //
  //
  //--color1: #0E0D19;
  //--color2: #3D53EC;
  //--color3: #31A8E0;
  //--color4: #E52C34;
  //--color5: #EED2E8;
}

* {
  box-sizing: border-box;
}

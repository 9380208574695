$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-tiktok: "\e91b";
$icon-arrow-short-left: "\e919";
$icon-arrow-short-right: "\e91a";
$icon-mute: "\e917";
$icon-unmute: "\e918";
$icon-arrow-longer-right: "\e910";
$icon-next: "\e903";
$icon-pause: "\e904";
$icon-play: "\e905";
$icon-prev: "\e906";
$icon-repeat: "\e907";
$icon-share: "\e908";
$icon-arrow-long-left: "\e901";
$icon-arrow-long-right: "\e902";
$icon-instagram: "\e90a";
$icon-facebook: "\e90b";
$icon-twitter: "\e90c";
$icon-vk: "\e90d";
$icon-youtube: "\e90e";
$icon-user: "\e900";
$icon-arrow-down: "\e90f";
$icon-arrow-left: "\e909";
$icon-arrow-right: "\e916";
$icon-email: "\e911";
$icon-eye-closed: "\e912";
$icon-eye-opened: "\e913";
$icon-phone: "\e914";
$icon-close: "\e915";
$icon-check: "\ea10";


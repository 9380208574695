.header {
  display: block;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 10;
  background: transparent;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: block;
  height: 54px;
  outline: none;
  img {
    display: block;
    height: 100%;
    width: auto;
  }
}

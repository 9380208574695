@import "../../../styles/settings/settings";
@import "../../../styles/tools/media";

@import "../../../assets/fonts/icomoon/variables";

$first: var(--color-player-a);
$second: var(--color-player-b);

.react-jinke-music-player-main {
  .music-player-panel {
    background: #000;
    box-shadow: none;
    height: 100px;
    border-top: 1px solid #434343;
    @include media('screen', '<768px') {
      height: auto;
      padding: 10px 0;
    }
    &.translate {
      animation: translateY .6s cubic-bezier(.43,-.1,.16,1.1) 0.2s;
    }
    .panel-content {
      width: 100%;
      max-width: 1260px;
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;
      justify-content: space-between;
      @include media('screen', '<768px') {
        flex-direction: column;
        padding: 0 0;
      }
      .img-content {
        display: none;
      }
      .img-rotate {
        animation: none;
        border-radius: 0;
      }

      .player-content {
        padding: 0 20px;
        @include media('screen', '<1201px') {
          padding: 0 10px;
        }
        @include media('screen', '<768px') {
          order: 1;
          padding: 0 10px;
          margin-bottom: 10px;
        }
        > .group {
          &.hide-panel {
            display: none;
          }
        }
      }

      .progress-bar-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 815px;
        padding: 0 20px;
        @include media('screen', '<1201px') {
          padding: 0 10px;
        }
        @include media('screen', '<768px') {
          display: flex!important;
          order: 2;
          margin: 0 0;
          padding: 0 10px;
          width: 100%;
          max-width: inherit;
        }
        .audio-title {
          order: 2;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1.2;
          letter-spacing: 0.03em;
          color: #828282;
          @include media('screen', '<1025px') {
            font-size: 14px;
          }
        }

        .audio-main {
          order: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding: 0 0 18px;
          margin: 0 0 10px;
          @include media('screen', '<768px') {
            padding: 0 0 18px 0;
          }
          .current-time,
          .duration {
            display: block;
            position: absolute;
            bottom: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.03em;
            color: #828282;
            text-align: left;
          }
          .current-time {
            left: 0;
          }
          .duration {
            right: 0;
            text-align: right;
          }
        }

        .progress-bar {
          margin: 0 0;
          .rc-slider-rail {
            background: rgba(#4F4F4F,0.11);
            opacity: 0;
          }
          .rc-slider-track {
            background: linear-gradient(270deg, $first 0%, $second 100%);
          }
          .rc-slider-handle {
            border: none;
            background: $first;
          }
          .rc-slider-step {
            background: #4F4F4F;
            border-radius: 2px;
          }
          .progress-load-bar {
            background-color: rgba(#fff,.2);
          }
        }
      }

      .player-content {

        .play-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin: 0 24px;
          width: 64px;
          height: 64px;
          background: linear-gradient(140deg, $first, $second);
          border-radius: 50%;
          @include media('screen', '<768px') {
            width: 46px;
            height: 46px;
          }
          span {
            font-size: 24px;
            color: #fff;
            @include media('screen', '<768px') {
              font-size: 18px;
            }
            &.icon-play {
              margin-left: 4px;
              @include media('screen', '<768px') {
                margin-left: 3px;
              }
            }
          }
          img {
            width: 64px;
            height: 64px;
          }
          svg {
            display: none;
          }
        }

        .loading-icon {
          padding: 0;
          margin: 0 24px;
          .loading {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            background: linear-gradient(140deg, $first, $second);
            border-radius: 50%;
            @include media('screen', '<768px') {
              width: 46px;
              height: 46px;
            }
          }
          img {
            width: 34px;
            height: 34px;
            @include media('screen', '<768px') {
              width: 28px;
              height: 28px;
            }
          }
          svg {
            color: $primary_color;
            width: 34px;
            height: 34px;
          }
        }

        .play-sounds {
          margin-right: 0;
        }

        .prev-audio,
        .next-audio {
          &:hover {
            svg {
              color: #fff;
            }
          }
          svg {
            font-size: 38px;
            color: #828282;
            transition: color 0.1s ease 0s;
          }
        }

        .sound-operation {
          .rc-slider-rail {
            background: rgba(#4F4F4F,0.11);
            opacity: 0;
          }
          .rc-slider-track {
            background: linear-gradient(270deg, $first 0%, $second 100%);
            z-index: 1;
          }
          .rc-slider-handle {
            border: none;
            //background: #fff;
            background: $first;
            z-index: 10;
          }
          .rc-slider-step {
            background: #4F4F4F;
            border-radius: 2px;
          }
        }

        .audio-lists-btn {
          display: none;
        }
      }

      .rc-slider {
        @include media('screen', '<768px') {
          margin: 0;
        }
      }
    }
  }


  svg:active,
  svg:hover {
    color: #fff;
  }
}

.audio-lists-panel {
  bottom: 100px;
  height: 300px;
}
.audio-lists-panel-content {
  height: 230px;
  .audio-item {
    color: #828282;
    &.playing {
      color: #fff;
      svg {
        color: #fff;
      }
    }
    .player-icons {
      img {
        width: 30px;
        height: 30px;
      }
    }

    .player-delete {
      display: none;
    }
  }
}

.audio-lists-panel-header-delete-btn {
  display: none;
}
.audio-lists-panel-header-line {
  display: none;
}



@keyframes translateY {
  from {
    transform: translate3d(0, 150%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

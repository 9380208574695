@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';


.sort {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4F4F4F;
  margin-right: -10px;

  .text {
    font-size: 14px;
    font-weight: 500;
  }

  .nav {
    margin-left: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    transform: rotate(-90deg);
    cursor: pointer;

    > span {
      width: 20px;
      height: 20px;
      text-align: center;
    }

    &:hover {
      color: #E0E0E0;
    }

    &.active {
      color: #E0E0E0;
      pointer-events: none;
    }
  }
}

@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.section {
  padding: 20px 0;
  @include media('screen', '<768px') {
    padding: 10px 0;
  }
}

.podcast {
  min-height: 463px;
  position: relative;
  @include media('screen', '<768px') {
    min-height: 310px;
  }

  @include media('screen', '<1025px') {
    min-height: 400px;
    margin: 0 -10px;
  }

  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 463px;
    min-height: 463px;
    background-repeat: repeat;
    background-size: cover;
    border-radius: 22px;
    opacity: 0.5;
    z-index: -1;

    @include media('screen', '<1260px') {
      border-radius: 0;
    }

    @include media('screen', '<1024px') {
      min-height: 400px;
      height: 400px;
    }

    @include media('screen', '<768px') {
      min-height: 310px;
      height: 310px;
    }

    @include media('screen', '<768px', 'portrait') {
      background-position: center;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .wrap {
    padding: 47px 47px 0;
    @include media('screen', '<1025px') {
      padding: 32px 25px 0;
    }

    @include media('screen', '<768px') {
      padding: 32px 10px 0;
    }
  }

  .album {
    max-width: 276px;
    margin-bottom: 50px;

    @include media('screen', '<768px') {
      max-width: 247px;
    }

    @include media('screen', '<768px', 'portrait') {
      margin: 0 auto 35px;
    }

    img {
      width: 100%;
      border-radius: 30px;

      @include media('screen', '<768px') {
        border-radius: 28px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @include media('screen', '<768px') {
      flex-wrap: wrap;
    }
  }

  .header {
    flex: 0 0 60%;
    max-width: 60%;

    @include media('screen', '<768px') {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #fff;
    @include media('screen', '<768px') {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .description {
    line-height: 1.4;
    font-size: 12px;
    font-weight: 400;
    max-width: 642px;
    letter-spacing: 0.03em;
    color: #fff;

    @include media('screen', '<768px') {
      margin-bottom: 35px;
      line-height: 1.2;
    }
  }

  .subscribe {
    flex: 0 0 40%;
    max-width: 40%;

    @include media('screen', '<768px') {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &--content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      @include media('screen', '<768px') {
        justify-content: flex-start;
      }
    }

    &--icon {
      width: 36px;
      height: 36px;

      img {
        width: 100%;
      }
    }

    &--link {
      margin-right: 22px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.playlist {
  $self: &;
  margin-top: 30px;
  padding: 0 47px;

  @include media('screen', '<1024px') {
    padding: 0 30px;
    margin-top: 20px;
  }

  @include media('screen', '<768px') {
    margin-top: 20px;
    padding: 0 0;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    @include media('screen', '<768px') {
      margin-bottom: 25px;
    }
  }

  &_title {
    font-weight: 800;
    font-size: 24px;
    line-height: 1;
    color: #fff;
    letter-spacing: 0.03em;
    @include media('screen', '<1260px') {
      font-size: 20px;
    }
    @include media('screen', '<768px') {
      font-size: 18px;
    }
  }


  &_footer {
    padding-top: 60px;
  }
}

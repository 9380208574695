$height: 50px;
$height_mobile: 50px;
$min_width: 160px;
$font_family: $primary_font;
$font_size: 18px;
$font_size_mobile: 18px;
$font_weight: bold;
$font_style: normal;
$text_color: #000;
$bg_color: $primary_color;
$border: none;
$border_radius: 25px;
$box_shadow: none;
$padding: 0 24px;

.c-btn {
  $self: &;
  position: relative;
  display: block;
  height: $height;
  width: 100%;
  line-height: 1;
  cursor: pointer;
  border: $border;
  border-radius: $border_radius;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  color: $text_color;
  background-color: $bg_color;
  box-shadow: $box_shadow;
  padding: $padding;
  margin: 0;
  user-select: none;
  outline: none;
  -webkit-appearance: none;
  transition: all .2s ease;
  &:hover {
    background-color: $hover_color;
    .c-btn__text {
      color: #000;
    }
  }
  &__text {
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: $font_family;
    font-size: $font_size;
    font-weight: $font_weight;
    font-style: $font_style;
  }
  &.is-disabled {
    background-color: darken(#C40047,8%);
    pointer-events: none;
  }
  &--small {
    width: auto;
    height: 40px;
    padding: 0 30px;
    .c-btn__text {
      font-size: 18px;
      padding-top: 0;
    }
  }
  &--large {
    width: auto;
    height: 76px;
    padding: 0 30px;
    .c-btn__text {
      font-size: 28px;
      padding-top: 0;
    }
  }
  &--desktop {
    display: block;
  }
  &--mob {
    display: none;
  }

  &--gray {
    background-color: #E0E0E0;
    &:hover {
      background-color: #C6C6C6;
      #{$self}__text {
        color: #4C4C4C;
      }
    }
    #{$self}__text {
      color: #7F7F7F;
    }
  }
}

@include media('screen', '<1025px') {
  .c-btn {
    height: $height_mobile;
    &__text {
      font-size: $font_size_mobile;
    }
    &--small {
      height: 35px;
      .c-btn__text {
        font-size: 16px;
      }
    }
    &--large {
      height: 50px;
      .c-btn__text {
        font-size: 24px;
      }
    }
  }
}

@include media('screen', '<768px') {
  .c-btn {
    padding: 0 20px;
    &--desktop {
      display: none;
    }
    &--mob {
      display: block;
    }
    &--small {
      height: 28px;
      .c-btn__text {
        font-size: 14px;
      }
    }
    &--large {
      height: 48px;
      .c-btn__text {
        font-size: 18px;
      }
    }
  }
}

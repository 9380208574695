@import '../../../styles/settings/settings';
@import '../../../styles/tools/media';

.first_grid {
  .col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px 30px 15px;

    @include media('screen', '<1260px') {
      padding: 0 10px 15px;
    }

    @include media('screen', '<1024px') {
      padding: 0 5px 10px;
    }

    &:first-child {
      flex: 0 0 75%;
      max-width: 75%;

      @include media('screen', '<1260px') {
        flex: 0 0 80%;
        max-width: 80%;
      }

      @include media('screen', '<768px') {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      flex: 0 0 25%;
      max-width: 25%;

      @include media('screen', '<1260px') {
        flex: 0 0 20%;
        max-width: 20%;
      }

      @include media('screen', '<768px') {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &:nth-child(4) {
      flex: 0 0 50%;
      max-width: 50%;

      @include media('screen', '<768px') {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.second_grid {
  .col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px 30px 15px;

    @include media('screen', '<1260px') {
      padding: 0 10px 15px;
    }

    @include media('screen', '<1024px') {
      padding: 0 5px 10px;
    }
  }
}

.third_grid {
  .col {
    padding: 0 15px 30px 15px;
    flex: 0 0 25%;
    max-width: 25%;

    @include media('screen', '<1260px') {
      padding: 0 10px 15px;
    }

    @include media('screen', '<1024px') {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 5px 10px;
    }

    @include media('screen', '<768px') {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {

      @include media('screen', '<1024px') {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}


.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  letter-spacing: 0.03em;

  @include media('screen', '<1260px') {
    margin: 0 -10px;
  }

  @include media('screen', '<1024px') {
    margin: 0 -5px;
  }
}

.footer {
  padding-top: 60px;
  @include media('screen', '<768px') {
    padding-top: 30px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0;
}